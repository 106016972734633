import React, { FC, useState } from 'react'

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid'
import type {
  ClientFeature,
  ClientFeaturesQuery,
  ClientFeaturesQueryVariables,
  CreateClientFeature,
  CreateClientFeatureVariables,
  DeleteClientFeature,
  DeleteClientFeatureVariables,
  LimitBehaviour,
  UpdateClientFeature,
  UpdateClientFeatureVariables,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import { default as EmptyComponent } from 'src/components/Library/Empty/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import Switch from 'src/components/Library/Switch/Switch'
import Modal from 'src/components/Modal/Modal'
import {
  CREATE_CLIENT_FEATURE_MUTATION,
  DELETE_CLIENT_FEATURE_MUTATION,
  UPDATE_CLIENT_FEATURE_MUTATION,
} from 'src/lib/queries/Feature/ClientFeature'
import { updateMap } from 'src/Util'

export const QUERY = gql`
  query ClientFeaturesQuery {
    clientFeatures {
      id
      client {
        id
        name
      }
      behaviour
      limit
      usage
      feature {
        id
        name
      }
      enabled
    }
    features {
      id
      name
    }
    clients {
      id
      name
    }
  }
`

const GET_FEATURES = gql`
  query GetFeatures {
    clientFeatureListByClient {
      name
      enabled
    }
  }
`

export const Loading = () => <LoadingSpinner />

const behaviourOptions = [
  { label: 'Silent', value: 'SILENT' },
  { label: 'Warning', value: 'WARNING' },
  { label: 'Disable', value: 'DISABLE' },
]

type Props = CellSuccessProps<ClientFeaturesQuery, ClientFeaturesQueryVariables>

export const Empty: FC<Props> = ({ clients, features }) => {
  const open = useBoolean(false)
  const [client, setClient] = useState<number | null>(null)
  const [feature, setFeature] = useState<number | null>(null)
  const [behaviour, setBehaviour] = useState<LimitBehaviour>(null)
  const [createClientFeature] = useMutation<
    CreateClientFeature,
    CreateClientFeatureVariables
  >(CREATE_CLIENT_FEATURE_MUTATION, {
    refetchQueries: ['ClientFeaturesQuery', GET_FEATURES],
    awaitRefetchQueries: true,
  })
  const [myMap, setMyMap] = useState(
    new Map([
      ['Usage', ''],
      ['Limit', ''],
    ]),
  )
  return (
    <>
      <Modal
        onClose={open.setFalse}
        open={open.value}
        childClassName={'p-6'}
        title={'Add Client Feature'}
        footerVisible
        onConfirm={() => {
          if (
            client !== null &&
            feature !== null &&
            behaviour !== null &&
            myMap.get('Usage') !== '' &&
            myMap.get('Limit') !== ''
          ) {
            createClientFeature({
              variables: {
                input: {
                  clientId: client,
                  featureId: feature,
                  usage: parseInt(myMap.get('Usage')),
                  limit: parseInt(myMap.get('Limit')),
                  enabled: false,
                  behaviour,
                  period: 'MONTHLY',
                },
              },
            })
            open.setFalse()
          } else {
            toast.error('Please fill out all fields')
          }
        }}
      >
        <Stack spacing={2}>
          <Autocomplete
            options={clients}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Client" />}
            onChange={(_, value) => setClient(value.id)}
          />

          <Autocomplete
            options={features}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Feature" />}
            onChange={(_, value) => setFeature(value.id)}
          />
          <Autocomplete
            options={behaviourOptions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Behaviour" />
            )}
            onChange={(_, value) => setBehaviour(value.value as LimitBehaviour)}
          />

          <SimpleForm
            records={myMap}
            handleChange={(key, value) =>
              updateMap(key, value, setMyMap, myMap)
            }
          />
        </Stack>
      </Modal>
      <EmptyComponent title="No Client Features">
        <Button fullWidth={false} onClick={() => open.setTrue()}>
          Add Client
        </Button>
      </EmptyComponent>
    </>
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<Props> = ({ clientFeatures, clients, features }) => {
  const open = useBoolean(false)
  const [client, setClient] = useState<number | null>(null)
  const [feature, setFeature] = useState<number | null>(null)
  const [behaviour, setBehaviour] = useState(null)
  const [behaviourInput, setBehaviourInput] = useState('')
  const loading = useBoolean(false)
  const editing = useBoolean(false)
  const [myMap, setMyMap] = useState(
    new Map([
      ['Usage', ''],
      ['Limit', ''],
    ]),
  )
  const [selected, setSelected] = useState<ClientFeature | null>(null)

  const [deleteClientFeature] = useMutation<
    DeleteClientFeature,
    DeleteClientFeatureVariables
  >(DELETE_CLIENT_FEATURE_MUTATION, {
    onCompleted: () => {
      loading.setFalse()
      toast.success('Client Feature Deleted')
    },
    onError: () => {
      loading.setFalse()
      toast.error('Error Deleting Client Feature')
    },
    refetchQueries: ['ClientFeaturesQuery', GET_FEATURES],
    awaitRefetchQueries: true,
  })

  const [createClientFeature] = useMutation<
    CreateClientFeature,
    CreateClientFeatureVariables
  >(CREATE_CLIENT_FEATURE_MUTATION, {
    refetchQueries: ['ClientFeaturesQuery', GET_FEATURES],
    awaitRefetchQueries: true,
    onCompleted: () => {
      loading.setFalse()
      toast.success('Client Feature Created')
      open.setFalse()
      editing.setFalse()
    },
    onError: () => {
      loading.setFalse()
      toast.error('Error Creating Client Feature')
    },
  })

  const [updateFeature] = useMutation<
    UpdateClientFeature,
    UpdateClientFeatureVariables
  >(UPDATE_CLIENT_FEATURE_MUTATION, {
    onCompleted: () => {
      loading.setFalse()
      toast.success('Client Feature Updated')
      open.setFalse()
      editing.setFalse()
    },
    onError: () => {
      loading.setFalse()
      toast.error('Error Updating Client Feature')
    },
    refetchQueries: ['ClientFeaturesQuery', GET_FEATURES],
    awaitRefetchQueries: true,
  })

  const columns: GridColDef[] = [
    {
      field: 'active',
      headerName: 'Active',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.enabled) {
          return (
            <svg
              className={'h-3 w-3 animate-pulse text-green-500'}
              fill="currentColor"
              viewBox="0 0 8 8"
            >
              <circle cx={4} cy={4} r={3} />
            </svg>
          )
        } else {
          return null
        }
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'feature',
      headerName: 'feature Name',
      valueGetter: (_value, row) => {
        return row.feature.name
      },
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'client',
      headerName: 'Client Name',
      valueGetter: (_value, row) => {
        return row.client.name
      },
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'usage',
      headerName: 'Usage',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'limit',
      headerName: 'Limit',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'behaviour',
      headerName: 'Behaviour',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.enabled}
            onChange={() => {
              loading.setTrue()
              updateFeature({
                variables: {
                  id: params.row.id,
                  input: { enabled: !params.row.enabled },
                },
              })
            }}
            name="enabled"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id + 'edit'}
          icon={<PencilIcon className={'h-4 w-4'} />}
          label="Edit"
          onClick={() => {
            setMyMap(
              new Map([
                ['Usage', params.row.usage],
                ['Limit', params.row.limit],
              ]),
            )

            setBehaviour(
              behaviourOptions.filter(
                (option) => option.value === params.row?.value,
              )[0]?.label,
            )
            setSelected(params.row)
            editing.setTrue()
            open.setTrue()
          }}
        />,
        <GridActionsCellItem
          key={params.id + 'delete'}
          icon={<TrashIcon className={'h-4 w-4'} />}
          label="Delete"
          onClick={async () =>
            await deleteClientFeature({
              variables: {
                id: Number(params.id),
              },
            })
          }
        />,
      ],
    },
  ]

  const handleCreateUpdate = () => {
    loading.setTrue()
    if (editing.value) {
      if (
        behaviour !== '' &&
        myMap.get('Usage') !== '' &&
        myMap.get('Limit') !== ''
      ) {
        updateFeature({
          variables: {
            id: selected.id,
            input: {
              usage: parseInt(myMap.get('Usage')),
              limit: parseInt(myMap.get('Limit')),
              ...(behaviour && { behaviour: behaviour.value }),
            },
          },
        })
      } else {
        toast.error('Please fill all fields')
      }
    } else {
      if (
        client !== null &&
        feature !== null &&
        behaviour !== '' &&
        myMap.get('Usage') !== '' &&
        myMap.get('Limit') !== ''
      ) {
        createClientFeature({
          variables: {
            input: {
              clientId: client,
              featureId: feature,
              usage: parseInt(myMap.get('Usage')),
              limit: parseInt(myMap.get('Limit')),
              enabled: false,
              behaviour: behaviour.value,
              period: 'MONTHLY',
            },
          },
        })
      } else {
        toast.error('Please fill all fields')
      }
    }
  }

  const handleClose = () => {
    setClient(null)
    setFeature(null)
    setBehaviour('')
    setMyMap(
      new Map([
        ['Usage', ''],
        ['Limit', ''],
      ]),
    )
    open.setFalse()
    editing.setFalse()
  }

  return (
    <>
      <Modal
        onClose={handleClose}
        onCancel={handleClose}
        open={open.value}
        childClassName={'p-6'}
        title={editing.value ? 'Edit Client Feature' : 'Add Client Feature'}
        footerVisible
        onConfirm={handleCreateUpdate}
        loading={loading.value}
      >
        <Stack spacing={2}>
          {!editing.value && (
            <>
              <Autocomplete
                options={clients}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Client" />
                )}
                onChange={(_, value) => setClient(value.id)}
              />

              <Autocomplete
                options={features}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Feature" />
                )}
                onChange={(_, value) => setFeature(value.id)}
              />
            </>
          )}
          <Autocomplete
            options={behaviourOptions}
            getOptionLabel={(option) => option?.label}
            renderInput={(params) => (
              <TextField {...params} label="Behaviour" />
            )}
            inputValue={behaviourInput}
            onInputChange={(_, newInputValue) => {
              setBehaviourInput(newInputValue)
            }}
            value={behaviour}
            onChange={(_, value) => setBehaviour(value)}
          />

          <SimpleForm
            records={myMap}
            handleChange={(key, value) =>
              updateMap(key, value, setMyMap, myMap)
            }
          />
        </Stack>
      </Modal>

      <div className="mb-2 flex items-center justify-end">
        <Button
          fullWidth={false}
          onClick={() => {
            editing.setFalse()
            open.setTrue()
          }}
        >
          Add Client Feature
        </Button>
      </div>

      <DataGrid
        columns={columns}
        rows={clientFeatures}
        autoHeight
        loading={loading.value}
      />
    </>
  )
}
