import React from 'react'

import { Metadata } from '@redwoodjs/web'

import ClientFeaturesCell from 'src/components/ClientFeaturesCell'
import PageHeader from 'src/components/PageHeader/PageHeader'

const ClientFeaturesPage = () => {
  return (
    <>
      <Metadata title="Client Features" description="Client Features" />

      <PageHeader
        title={'Client Features'}
        parentDataTestId="client-features-page-header"
      />
      <div className="flex flex-1 justify-center h-[calc(100vh-5rem)]">
        <div className="px-8 pt-8 overflow-y-auto w-full h-full mb-2">
          <ClientFeaturesCell />
        </div>
      </div>
    </>
  )
}

export default ClientFeaturesPage
